import styled, { css } from "styled-components"
import BuildingSmallTop from "assets/images/cta-image-top.svg"
import BuildingSmallBottom from "assets/images/cta-image-bottom.svg"
import BuildingTop from "assets/images/cta-building-top.svg"
import BuildingBottom from "assets/images/cta-building-bottom.svg"

const CTAWrapper = styled.div`
  position: relative;
  margin-top: 8rem;
  padding: 10rem 2.4rem 10rem;
  background-color: ${({ theme }) => theme.color.secondary05};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${({ theme }) => theme.mq.large} {
    margin-top: 11rem;

    padding: 6rem 2.4rem 5.2rem;
  }
`

const bottomImage = css`
  position: absolute;
  bottom: 0;
  right: 0;
`

const topImage = css`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledMobileTopImage = styled(BuildingSmallTop)`
  ${topImage};

  ${({ theme }) => theme.mq.large} {
    display: none;
  }
`

const StyledMobileBottomImage = styled(BuildingSmallBottom)`
  ${bottomImage};

  ${({ theme }) => theme.mq.large} {
    display: none;
  }
`

const StyledDesktopTopImage = styled(BuildingTop)`
  ${topImage};

  display: none;
  ${({ theme }) => theme.mq.large} {
    display: block;
  }
`
const StyledDesktopBottomImage = styled(BuildingBottom)`
  ${bottomImage};

  display: none;
  ${({ theme }) => theme.mq.large} {
    display: block;
  }
`

const StyledTextWrapper = styled.div`
  position: relative;
  margin-bottom: 4rem;

  text-align: center;

  ${({ theme }) => theme.mq.large} {
    margin-bottom: 2.4rem;
  }
`

const paragraphStyles = css`
  margin-top: 1.2rem;
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};
`

const headingStyles = css`
  font-size: ${({ theme }) => theme.font.size.xxl};
  line-height: 1.25;
`

const buttonStyles = css`
  z-index: 1;
`

export {
  CTAWrapper,
  StyledTextWrapper,
  paragraphStyles,
  headingStyles,
  StyledMobileTopImage,
  StyledMobileBottomImage,
  StyledDesktopTopImage,
  StyledDesktopBottomImage,
  buttonStyles,
}
