import styled, { css } from "styled-components"

const StyledCRESection = styled.section`
  position: relative;
  overflow: hidden;
  margin-top: 14rem;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.color.main03};

  ${({ theme }) => theme.mq.medium} {
    background-image: url("data:image/svg+xml,%3Csvg width='1400' height='752' viewBox='0 0 1400 752' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-0.999895 0.864994L517.187 0.864746L700 99.9996L1402 100L1401 751.5L-1 751.5L-0.999895 0.864994Z' fill='%231A6BE6'/%3E%3Cpath d='M485.5 0H370L466.25 52H581.75L485.5 0Z' fill='%2323E7E5'/%3E%3Cpath d='M402 0H517.5L710 104H594.5L402 0Z' fill='%23083F91'/%3E%3C/svg%3E%0A");
    background-color: transparent;
    background-size: cover;
  }
`

const StyledContentWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 0rem 8rem;

  ${({ theme }) => theme.mq.medium} {
    max-width: ${({ theme }) => theme.gridWidth};
    flex-direction: row;
    align-items: center;
    gap: 1.6rem;

    padding: 18rem 1.6rem 8.7rem;
    margin: 0 auto;
  }

  ${({ theme }) => theme.mq.large} {
    gap: 8.8rem;
  }
`

const StyledTextWrapper = styled.div`
  padding: 0 1.6rem;
  margin-top: 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    padding: 0;
    max-width: 50%;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  width: 66rem;
  max-height: 40rem;
`

const StyledImage = styled.div`
  position: relative;
  width: 115%;
  left: -25rem;
  top: -15rem;

  ${({ theme }) => theme.mq.medium} {
    width: 130%;
    top: -3rem;
    left: -14rem;
  }

  ${({ theme }) => theme.mq.large} {
    left: -16rem;
  }
`

const headingStyles = css`
  color: ${({ theme }) => theme.color.white};
`

const paragraphStyles = css`
  margin-top: 1.6rem;
  color: ${({ theme }) => theme.color.white};
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};
`

const buttonStyles = css`
  color: ${({ theme }) => theme.color.secondary01};
  margin-top: 4rem;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 3.2rem;
  }
`

export {
  StyledCRESection,
  StyledContentWrapper,
  StyledTextWrapper,
  StyledImage,
  headingStyles,
  paragraphStyles,
  buttonStyles,
  ImageWrapper,
}
