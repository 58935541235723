import styled, { keyframes, css } from "styled-components"

const slideInfinite = (count) => keyframes`
0% {
    transform: translateX(${count * 23.5}rem)
}
100% {
    transform: translateX(-${count * 6}rem)
}
`

const StyledCarouselWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  height: 17.7rem;
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.gridWidth};
  margin-top: 2rem;
`
const StyledLogoBlock = styled.div`
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17.7rem;
  width: 26.5rem;
  position: absolute;
  background-color: ${({ theme, color }) => color || theme.color.secondary06};
  margin: 0 1rem;
  ${({ animationDelay }) =>
    css`
      animation-delay: -${animationDelay}s;
    `}
`

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ count }) => css`
    ${StyledLogoBlock} {
      animation-duration: ${count * 6}s;

      animation-name: ${slideInfinite(count)};
    }
  `}
`
const StyledImage = styled.img`
  width: auto;
  height: auto;
  max-width: calc(100% - 3.2rem);
  max-height: calc(100% - 3.2em);
`
export { StyledCarouselWrapper, StyledRow, StyledLogoBlock, StyledImage }
