import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import BenefitBlock from "./BenefitBlock"
import {
  StyledBenefitSection,
  StyledBenefitBlocksWrapper,
} from "./BenefitSection.styles"
import { StyledSectionHeader } from "../HomepageCommonComponents.styles"
import CTABlock from "./CTABlock"

const HomepageBenefitSection = ({ sectionData }) => {
  return (
    <StyledBenefitSection id="why-exdev">
      <StyledSectionHeader>
        <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
        <H as="h2">{sectionData.descriptiveSubheader}</H>
      </StyledSectionHeader>
      <StyledBenefitBlocksWrapper>
        <BenefitBlock
          index="01"
          link="/#frontrow-reporting"
          title={sectionData.transparencyHeader}
          description={sectionData.transparencyDescription}
        />
        <BenefitBlock
          index="02"
          link="/#direct-to-developer"
          title={sectionData.directToDeveloperHeader}
          description={sectionData.directToDeveloperDescription}
        />
        <BenefitBlock
          index="03"
          link="/#seamless-experience"
          title={sectionData.digitalExperienceHeader}
          description={sectionData.digitalExperienceDescription}
        />
      </StyledBenefitBlocksWrapper>
      <CTABlock
        ctaData={{
          header: sectionData.ctaHeader,
          subHeader: sectionData.ctaSubheader,
          buttonText: sectionData.ctaButtonText,
        }}
      />
    </StyledBenefitSection>
  )
}

export default HomepageBenefitSection

HomepageBenefitSection.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    transparencyHeader: PropTypes.string,
    transparencyDescription: PropTypes.string,
    directToDeveloperHeader: PropTypes.string,
    directToDeveloperDescription: PropTypes.string,
    digitalExperienceHeader: PropTypes.string,
    digitalExperienceDescription: PropTypes.string,
    ctaHeader: PropTypes.string,
    ctaSubheader: PropTypes.string,
    ctaButtonText: PropTypes.string,
  }).isRequired,
}
