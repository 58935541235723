import styled, { css } from "styled-components"

const HeavyLiftingWrapper = styled.div`
  padding: 0 1.6rem;

  max-width: ${({ theme }) => theme.gridWidth};
  margin: 3.2rem auto 0rem;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    margin-top: 7.4rem;
  }
`

const StyledChecklistWrapper = styled.div`
  padding: 1.6rem 1.8rem;
  max-width: 40rem;
  background-color: ${({ theme }) => theme.color.white};

  clip-path: polygon(0% 0%, 100% 0, 100% 75%, 75% 100%, 0% 100%);

  ${({ theme }) => theme.mq.small} {
    padding: 5rem 4.8rem;
  }

  ${({ theme }) => theme.mq.medium} {
    margin-right: 2.4rem;
  }
`

const StyledChecklist = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 0;
`

const StyledTextContent = styled.div`
  margin-top: 3.2rem;

  ${({ theme }) => theme.mq.medium} {
    max-width: 50%;
  }
`

const paragraphStyles = css`
  margin-top: 2.4rem;
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};
`

const headingStyles = css`
  font-size: ${({ theme }) => theme.font.size.xl};

  ${({ theme }) => theme.mq.medium} {
    font-size: ${({ theme }) => theme.font.size.xxl};
  }
`

export {
  HeavyLiftingWrapper,
  StyledChecklistWrapper,
  StyledChecklist,
  StyledTextContent,
  paragraphStyles,
  headingStyles,
}
