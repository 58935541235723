import styled from "styled-components"

const StyledWrapper = styled.li`
  display: flex;
  align-items: center;
  margin-top: 2.6rem;

  &:first-of-type {
    margin-top: 0;
  }

  svg {
    margin-right: 1.2rem;
  }
`

export { StyledWrapper }
