import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import paths from "utils/paths"
import BtnLink from "components/atoms/BtnLink"
import Button from "components/atoms/Button"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import {
  CTAWrapper,
  StyledTextWrapper,
  paragraphStyles,
  headingStyles,
  StyledMobileTopImage,
  StyledMobileBottomImage,
  StyledDesktopBottomImage,
  StyledDesktopTopImage,
  buttonStyles,
} from "./CTABlock.styles"

const CTABlock = ({ ctaData }) => {
  const { getStarted } = paths

  return (
    <CTAWrapper>
      <StyledDesktopTopImage />
      <StyledDesktopBottomImage />
      <StyledMobileTopImage />
      <StyledMobileBottomImage />
      <StyledTextWrapper>
        <H extraStyles={headingStyles} as="h3">
          {ctaData.header}
        </H>
        <P extraStyles={paragraphStyles}>{ctaData.subHeader}</P>
      </StyledTextWrapper>
      <BtnLink to={getStarted} extraStyles={buttonStyles}>
        <Button
          trackingOptions={{
            category: trackingCategoriesHelper.HOME,
            action: "Click",
            label: `${trackingCategoriesHelper.HOME} link(getStarted)`,
          }}
        >
          {ctaData.buttonText}
        </Button>
      </BtnLink>
    </CTAWrapper>
  )
}

export default CTABlock

CTABlock.propTypes = {
  ctaData: PropTypes.shape({
    header: PropTypes.string,
    subHeader: PropTypes.string,
    buttonText: PropTypes.string,
  }).isRequired,
}
