import React, { useEffect, useRef } from "react"
import H from "components/atoms/H"
import { useIntl } from "gatsby-plugin-intl"
import P from "components/atoms/P"
import lottie from "lottie-web"
import PropTypes from "prop-types"
import ReducedFees from "assets/animations/reduced-fees.json"
import ReducedFeesExdev from "assets/animations/reduced-fees-exdev.json"
import UnfilteredInformation from "assets/animations/unfiltered-information.json"
import UnfilteredInformationExdev from "assets/animations/unfiltered-information-exdev.json"
import {
  animationBlockHeading,
  animationParagraph,
  LeftAnimationWrapper,
  RightAnimationWrapper,
  AnimationCaption,
  animationLeftHeading,
  animationRightHeading,
  AnimationWrapper,
  AnimationContainer,
} from "./AnimationBlock.styles"

const animations = {
  REDUCED_FEES: {
    left: ReducedFees,
    right: ReducedFeesExdev,
  },
  DIRECT_ACCESS: {
    left: UnfilteredInformation,
    right: UnfilteredInformationExdev,
  },
}

const AnimationBlock = ({ animationType, sectionData, showCaption }) => {
  const { formatMessage } = useIntl()
  const leftAnimationContainer = useRef()
  const rightAnimationContainer = useRef()

  useEffect(() => {
    const leftAnimation = lottie.loadAnimation({
      container: leftAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animations[animationType].left,
    })

    const rightAnimation = lottie.loadAnimation({
      container: rightAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animations[animationType].right,
    })

    return () => {
      leftAnimation.destroy()
      rightAnimation.destroy()
    }
  }, [animationType])

  return (
    <AnimationWrapper>
      <H as="h4" extraStyles={animationBlockHeading}>
        {sectionData.header}
      </H>
      <P extraStyles={animationParagraph}>{sectionData.description}</P>
      <LeftAnimationWrapper>
        <H as="h5" extraStyles={animationLeftHeading}>
          {formatMessage({ id: "pages.home.directInvest.others" })}
        </H>
        <AnimationContainer
          animationType={animationType}
          ref={leftAnimationContainer}
          key={animationType}
        />
      </LeftAnimationWrapper>
      <RightAnimationWrapper>
        <H as="h5" extraStyles={animationRightHeading}>
          {formatMessage({ id: "pages.home.directInvest.exdev" })}
        </H>
        <AnimationContainer
          animationType={animationType}
          ref={rightAnimationContainer}
          key={animationType}
        />
      </RightAnimationWrapper>
      {showCaption && (
        <AnimationCaption>
          {formatMessage({ id: "pages.home.directInvest.animationCaption" })}
        </AnimationCaption>
      )}
    </AnimationWrapper>
  )
}

export default AnimationBlock

AnimationBlock.propTypes = {
  animationType: PropTypes.oneOf(["REDUCED_FEES", "DIRECT_ACCESS"]).isRequired,
  sectionData: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  showCaption: PropTypes.bool,
}

AnimationBlock.defaultProps = {
  showCaption: true,
}
