import styled, { css } from "styled-components"
import InvestBuilding from "assets/images/invest-at-source-building.svg"

const paragraphStyles = css`
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};
  margin-top: 3.2rem;

  order: 3;

  ${({ theme }) => theme.mq.large} {
    max-width: 70%;
    grid-area: paragraph;
    margin-top: 24px;
  }
`

const headerStyles = css`
  margin-bottom: 3.2rem;
  font-size: ${({ theme }) => theme.font.size.xl};

  ${({ theme }) => theme.mq.medium} {
    font-size: ${({ theme }) => theme.font.size.xxl};
  }

  ${({ theme }) => theme.mq.large} {
    margin-bottom: 0;
    align-self: end;
    grid-area: heading;
    margin-top: 6rem;
  }
`

const ContentWrapper = styled.div`
  padding: 4.8rem 1.6rem 0rem;
  max-width: ${({ theme }) => theme.gridWidth};
  margin: 0 auto;

  ${({ theme }) => theme.mq.medium} {
    padding: 14rem 1.6rem 0rem;
    margin-bottom: 0;
    align-self: end;
    grid-area: heading;
    margin-top: 6rem;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 3.2rem;

  ${({ theme }) => theme.mq.large} {
    display: grid;
    gap: 0rem;

    grid-template-areas:
      "heading image"
      "paragraph image";
  }
`

const StyledInvestImage = styled(InvestBuilding)`
  max-width: 40rem;

  ${({ theme }) => theme.mq.large} {
    grid-area: image;
    width: 40rem;
  }
`

export {
  paragraphStyles,
  headerStyles,
  ContentWrapper,
  HeadingWrapper,
  StyledInvestImage,
}
