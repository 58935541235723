import React, { useEffect, useRef, useState } from "react"

import H from "components/atoms/H"
import P from "components/atoms/P"
import Button from "components/atoms/Button"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import paths from "utils/paths"
import lottie from "lottie-web"
import { useInView } from "react-intersection-observer"
import HomeHeroAnimation from "assets/animations/home-hero-animation.json"
import {
  StyledHeroSection,
  SectionContentWrapper,
  paragraphStyles,
  TrustedBrandsWrapper,
  StyledHeroAnimation,
  StyledButtonWrapper,
} from "./HomepageHeroSection.styles"
import TrustedBrands from "./TrustedBrands"

const HomepageHeroSection = ({ sectionData }) => {
  const { header, description } = sectionData
  const { homepageAbout } = paths
  const { formatMessage } = useIntl()
  const [anim, setAnim] = useState(null)
  const [animIdle, setAnimIdle] = useState(false)
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const animationContainer = useRef()

  useEffect(() => {
    const hero = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: HomeHeroAnimation,
    })
    setAnim(hero)
    return () => hero.destroy()
  }, [])

  useEffect(() => {
    if (anim && anim.isPaused && inView && !animIdle) {
      anim.playSegments([[0, 450]], true)
      setAnimIdle(true)
    }
  }, [inView])

  useEffect(() => {
    let timer
    if (animIdle) {
      timer = setTimeout(() => {
        setAnimIdle(false)
      }, 45000)
    }
    return () => clearTimeout(timer)
  }, [animIdle])

  return (
    <StyledHeroSection ref={ref}>
      <SectionContentWrapper>
        <H as="h1">{header}</H>
        <P extraStyles={paragraphStyles}>{description}</P>
        <StyledButtonWrapper>
          <Button
            to={homepageAbout}
            type="secondaryFilled"
            trackingOptions={{
              category: `${trackingCategoriesHelper.HOME} button`,
              action: "Click",
              label: `${trackingCategoriesHelper.HOME} hero button(Learn more))`,
            }}
          >
            {formatMessage({
              id: `pages.home.hero.button`,
            })}
          </Button>
        </StyledButtonWrapper>
        <TrustedBrandsWrapper>
          <TrustedBrands />
        </TrustedBrandsWrapper>
      </SectionContentWrapper>
      <StyledHeroAnimation ref={animationContainer} />
    </StyledHeroSection>
  )
}

export default HomepageHeroSection

HomepageHeroSection.propTypes = {
  sectionData: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}
