import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import InvestContentBlock from "./InvestContentBlock"

import {
  paragraphStyles,
  headerStyles,
  ContentWrapper,
  HeadingWrapper,
  StyledInvestImage,
} from "./InvestAtSource.styles"

const InvestAtSource = ({ sectionData }) => {
  return (
    <ContentWrapper>
      <HeadingWrapper>
        <H as="h3" extraStyles={headerStyles}>
          {sectionData.header}
        </H>
        <P extraStyles={paragraphStyles}>{sectionData.description}</P>
        <StyledInvestImage />
      </HeadingWrapper>
      <InvestContentBlock
        animationType="REDUCED_FEES"
        sectionData={{
          firstParagraph: sectionData.alignedGoals,
          secondParagraph: sectionData.reducedFees,
        }}
      />
      <InvestContentBlock
        animationType="DIRECT_ACCESS"
        sectionData={{
          firstParagraph: sectionData.directAccessToDevelopmentTeam,
          secondParagraph: sectionData.unfilteredProjectInformation,
        }}
        showAnimationCaption={false}
      />
    </ContentWrapper>
  )
}

export default InvestAtSource

InvestAtSource.propTypes = {
  sectionData: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
    alignedGoals: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    directAccessToDevelopmentTeam: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    reducedFees: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
    }),
    unfilteredProjectInformation: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
}
