import styled, { keyframes, css } from "styled-components"

const scrolling = (count) => keyframes`
0% { transform: translateX(0); }
100% { transform: translateX(-${100 * count}%); }
`

const AnimationItem = styled.div`
  position: absolute;
  ${({ position }) => css`
    top: ${position.y}%;
    left: ${position.x}%;
  `}

  white-space: nowrap;
  padding: 0.7rem 1rem;

  ${({ colorSchema }) => css`
    background-color: ${colorSchema.background};
    color: ${colorSchema.font};
  `}
`

const AnimationWrapper = styled.div`
  min-height: 43rem;
  width: ${({ theme }) => theme.gridWidth};

  display: flex;

  animation-name: ${({ count }) => scrolling(count)};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
  animation-duration: ${({ count }) => count * 25}s;
`

const AnimationSectionWrapper = styled.div`
  position: relative;
  height: 43rem;
  width: ${({ theme }) => theme.gridWidth};

  flex-shrink: 0;
`

const SlidingAnimation = styled.div`
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(186, 211, 238, 1) 30%,
    rgba(246, 249, 253, 1) 90%
  );
`

const colorSchemas = [
  {
    background: "#FFBECA",
    font: "#AF0D2C",
  },
  {
    background: "#FFDA95",
    font: "#835E1B",
  },
  {
    background: "#DD9EFA",
    font: "#72129E",
  },
  {
    background: "#BFFDE6",
    font: "#078A5B",
  },
  {
    background: "#B7D9FD",
    font: "#1A6BE5",
  },
]

const positions = [
  {
    x: 75,
    y: 5,
  },
  {
    x: 80,
    y: 30,
  },
  {
    x: 45,
    y: 5,
  },
  {
    x: 74,
    y: 89,
  },
  {
    x: 10,
    y: 30,
  },
  {
    x: 81,
    y: 56,
  },
  {
    x: 50,
    y: 60,
  },
  {
    x: 43,
    y: 34,
  },
  {
    x: 11,
    y: 92,
  },
  {
    x: 20,
    y: 80,
  },
  {
    x: 1,
    y: 10,
  },
  {
    x: 8,
    y: 62,
  },
  {
    x: 20,
    y: 5,
  },
  {
    x: 22,
    y: 50,
  },
  {
    x: 28,
    y: 65,
  },
  {
    x: 62,
    y: 45,
  },
  {
    x: 85,
    y: 92,
  },
  {
    x: 28,
    y: 20,
  },
  {
    x: 44,
    y: 79,
  },
  {
    x: 55,
    y: 20,
  },
]

export {
  colorSchemas,
  positions,
  AnimationItem,
  AnimationSectionWrapper,
  AnimationWrapper,
  SlidingAnimation,
}
