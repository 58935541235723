import styled from "styled-components"

const SectionWrapper = styled.section`
  padding: 8rem 1.6rem 7rem;

  ${({ theme }) => theme.mq.large} {
    padding: 9rem 1.6rem 13rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`
const ContentWrapper = styled.div`
  margin-top: 4rem;

  ${({ theme }) => theme.mq.small} {
    margin-top: 6rem;
  }
`

export { SectionWrapper, ContentWrapper }
