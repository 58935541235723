import React from "react"
import PropTypes from "prop-types"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import H from "components/atoms/H"
import { StyledSectionHeader } from "../HomepageCommonComponents.styles"
import HeavyLifting from "./HeavyLifting"
import InvestAtSource from "./InvestAtSource"

import { SectionWrapper, ContentWrapper } from "./DirectInvestingSection.styles"

const DirectInvestingSection = ({ sectionData }) => {
  const {
    directToDeveloperSection,
    heavyLiftingSection,
    investAtSourceSection,
    animationData,
  } = sectionData

  return (
    <SectionWrapper id="direct-to-developer">
      <ContentWrapper>
        <StyledSectionHeader>
          <SectionSubtitle>
            {directToDeveloperSection.accentMainHeader}
          </SectionSubtitle>
          <H as="h2">{directToDeveloperSection.descriptiveSubheader}</H>
        </StyledSectionHeader>
      </ContentWrapper>
      <HeavyLifting sectionData={{ ...heavyLiftingSection, animationData }} />
      <InvestAtSource sectionData={investAtSourceSection} />
    </SectionWrapper>
  )
}

export default DirectInvestingSection

DirectInvestingSection.propTypes = {
  sectionData: PropTypes.shape({
    directToDeveloperSection: PropTypes.shape({
      accentMainHeader: PropTypes.string,
      descriptiveSubheader: PropTypes.string,
    }),
    heavyLiftingSection: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
      highlightsSection: PropTypes.arrayOf(
        PropTypes.shape({
          position: PropTypes.number,
          highlight: PropTypes.string,
        })
      ),
    }),
    investAtSourceSection: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
      alignedGoals: PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      directAccessToDevelopmentTeam: PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      reducedFees: PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
      }),
      unfilteredProjectInformation: PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
    animationData: PropTypes.shape({
      processLabelSection: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
}
