import React from "react"
import { PropTypes } from "prop-types"
import Checkmark from "assets/icons/check-mark-primary.svg"
import { StyledWrapper } from "./ChecklistItem.styles"

const ChecklistItem = ({ children }) => {
  return (
    <StyledWrapper>
      <Checkmark />
      {children}
    </StyledWrapper>
  )
}

export default ChecklistItem

ChecklistItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}
