import styled from "styled-components"
import FlowAnimation from "./FlowAnimation"

const AnimationWrapper = styled.div`
  position: relative;
  margin-top: 4.8rem;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 16rem;
  }
`

const AnimationMask = styled.div`
  position: absolute;
  width: 80%;
  height: 102%;
  z-index: 2;

  top: -1%;
  left: 0;

  background-color: ${({ theme }) => theme.color.secondary05};
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);

  ${({ theme }) => theme.mq.small} {
    width: 50%;
  }
`

const StyledFlowAnimation = styled(FlowAnimation)`
  position: absolute;
  right: 25%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  ${({ theme }) => theme.mq.small} {
    right: 55%;
  }

  ${({ theme }) => theme.mq.extraLarge} {
    right: 60%;
  }
`
export { AnimationWrapper, AnimationMask, StyledFlowAnimation }
