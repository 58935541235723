import styled, { css } from "styled-components"

const StyledList = styled.ul`
  margin: 0;
  padding: 0;

  position: relative;
  flex-grow: 1;

  list-style: none;

  ${({ theme }) => theme.mq.small} {
    padding-left: 1.6rem;
    margin-left: 7.6rem;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 90%;

      background-image: url("data:image/svg+xml,%3Csvg width='1' height='410' viewBox='0 0 1 410' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='2.18557e-08' x2='0.499982' y2='410' stroke='%23A3AFC2' stroke-dasharray='4 4'/%3E%3C/svg%3E%0A");
    }
  }
`
const StyledListItem = styled.li`
  position: relative;
  margin-bottom: 3.2rem;

  ${({ theme }) => theme.mq.small} {
    margin-bottom: 6.6rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const listNumberStyle = css`
  font-size: 2.8rem;
  color: ${({ theme }) => theme.color.secondary03};

  position: absolute;
  top: 0;

  ${({ theme }) => theme.mq.small} {
    font-size: 4.8rem;

    left: -8.5rem;
  }
`

const titleStyle = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.l};

  margin-left: 4.8rem;
  margin-bottom: 0.8rem;
  ${({ theme }) => theme.mq.small} {
    margin-left: 0rem;
  }
`

const buttonStyles = css`
  margin-top: 6rem;
  order: 4;
`

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
`

export {
  StyledList,
  StyledListItem,
  listNumberStyle,
  buttonStyles,
  titleStyle,
  paragraphStyles,
}
