import React from "react"
import PropTypes from "prop-types"

import {
  StyledCarouselWrapper,
  StyledRow,
  StyledLogoBlock,
  StyledImage,
} from "./LogoCarousel.styles"

const LogoCarousel = ({ images, delay, customTileColor }) => {
  return (
    <StyledCarouselWrapper>
      <StyledRow count={images.length}>
        {images.map((image, index) => (
          <StyledLogoBlock
            key={image.id}
            animationDelay={6 * index + delay}
            color={customTileColor}
          >
            <StyledImage alt={image.title} src={image.image} />
          </StyledLogoBlock>
        ))}
      </StyledRow>
    </StyledCarouselWrapper>
  )
}

LogoCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      title: PropTypes.string,
    })
  ).isRequired,
  delay: PropTypes.number,
  customTileColor: PropTypes.string,
}
LogoCarousel.defaultProps = {
  delay: 0,
  customTileColor: "",
}
export default LogoCarousel
