import styled, { css } from "styled-components"

const Table = styled.table`
  border-collapse: collapse;
`

const TableRowHeader = styled.th`
  text-align: left;
  width: 50%;
  padding: 1.6rem 0rem 2rem;

  color: ${({ theme }) => theme.color.secondary01};
  font-size: ${({ theme }) => theme.font.size.l};
`

const TableColumnHeader = styled.th`
  width: 15%;

  color: ${({ theme }) => theme.color.secondary02};
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      color: ${theme.color.septenary03};
      font-weight: ${theme.font.weight.semiBold};
    `}
`

const TableRowDescription = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin-top: 0.8rem;
`

const TableCell = styled.td`
  text-align: center;
`

const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};

  &:last-of-type {
    border-bottom: none;
  }
`

export {
  Table,
  TableRowHeader,
  TableColumnHeader,
  TableRowDescription,
  TableCell,
  TableRow,
}
