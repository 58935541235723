import styled, { css } from "styled-components"
import ReactPlayer from "react-player/lazy"
import P from "components/atoms/P"
import H from "components/atoms/H"
import BrowserBar from "assets/images/browser-bar.svg"

const SectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3.2rem;

  flex-direction: column;
  overflow: hidden;

  ${({ theme }) => theme.mq.large} {
    flex-direction: row;
  }
`

const StyledBrowserBar = styled(BrowserBar)`
  width: 54rem;

  ${({ theme }) => theme.mq.extraLarge} {
    width: 80rem;
  }
`

const SidelistWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: scroll;

  max-width: 100%;
  ${({ theme }) => theme.mq.large} {
    max-width: 30rem;
    flex-direction: column;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    /* stylelint-disable */
    -webkit-appearance: none;
    /* stylelint-enable */
    display: none;
    width: 0;
    height: 0;

    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`

const customStageListStyles = css`
  border: none;
  margin-bottom: 2.4rem;
  min-width: 20rem;
`
const customContentStyles = css`
  padding: 0;
`

const StyledHeading = styled(H)`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  margin: 0;

  color: ${({ theme }) => theme.color.secondary02};

  ${({ theme, isActive }) =>
    isActive &&
    css`
      color: ${theme.color.main03};
    `};
`

const StyledParagraph = styled(P)`
  margin-top: 0.4rem;
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  color: ${({ theme }) => theme.color.secondary02};

  ${({ theme, isActive }) =>
    isActive &&
    css`
      color: ${theme.color.secondary01};
    `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: scroll;

  padding-bottom: 2rem;

  ${({ theme }) => theme.mq.medium} {
    margin: 0 auto;
    align-items: center;
  }

  ${({ theme }) => theme.mq.large} {
    margin: 0 auto;
    align-items: center;
    overflow: hidden;
  }

  video {
    object-fit: cover;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    /* stylelint-disable */
    -webkit-appearance: none;
    /* stylelint-enable */
    display: none;
    width: 0;
    height: 0;

    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`

const BrowserWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.secondary04};

  display: flex;
  flex-direction: column;

  padding: 1.7rem 1.7rem 0;
  width: 54rem;
  box-sizing: content-box;

  ${({ theme }) => theme.mq.extraLarge} {
    padding: 2.6rem 2.6rem 0;
    width: 80rem;
  }
`

const PlayerWrapper = styled.div`
  position: relative;

  width: 54rem;
  height: 30rem;

  ${({ theme }) => theme.mq.extraLarge} {
    width: 80rem;
    height: 44rem;
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  background-color: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 0;
  left: 0;
`

const videoDescriptionStyles = css`
  max-width: 80vw;
  margin-top: 1.6rem;
  color: ${({ theme }) => theme.color.secondary02};

  ${({ theme }) => theme.mq.medium} {
    margin-top: 2.4rem;
  }
`

export {
  SectionWrapper,
  SidelistWrapper,
  customStageListStyles,
  customContentStyles,
  ContentWrapper,
  BrowserWrapper,
  PlayerWrapper,
  StyledReactPlayer,
  videoDescriptionStyles,
  StyledHeading,
  StyledParagraph,
  StyledBrowserBar,
}
