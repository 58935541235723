import { useIntl } from "gatsby-plugin-intl"
import getMediaUrl from "utils/getMediaUrl"

const getShowcaseItem = ({ messageId, url, customComponent }) => {
  const { formatMessage } = useIntl()

  return {
    title: formatMessage({ id: `pages.home.${messageId}.title` }),
    description: formatMessage({
      id: `pages.home.${messageId}.description`,
    }),
    videoUrl: getMediaUrl(url),
    customComponent,
  }
}

export default getShowcaseItem
