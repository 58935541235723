import React from "react"
import PropTypes from "prop-types"

import H from "components/atoms/H"
import P from "components/atoms/P"
import CheckMark from "assets/icons/table-check-mark.svg"
import CrossMark from "assets/icons/table-cross-mark.svg"
import { useIntl } from "gatsby-plugin-intl"
import {
  TableContainer,
  StickyHeader,
  ColumnHeader,
  RowChecks,
  RowWrapper,
  TableCell,
  TableBody,
  headingStyles,
  paragraphStyles,
} from "./MobileDataTable.styles"

const MobileDataTable = ({ tableData }) => {
  const { formatMessage } = useIntl()
  const renderCell = (value) => (value ? <CheckMark /> : <CrossMark />)

  const renderRow = (rowData) => (
    <RowWrapper key={rowData.position}>
      <H as="h3" extraStyles={headingStyles}>
        {rowData.title}
      </H>
      <P extraStyles={paragraphStyles}>{rowData.description}</P>
      <RowChecks>
        <TableCell>{renderCell(rowData.exdevHas)}</TableCell>
        <TableCell>{renderCell(rowData.crowdfundingSitesHave)}</TableCell>
        <TableCell>{renderCell(rowData.otherDevelopersHave)}</TableCell>
      </RowChecks>
    </RowWrapper>
  )

  return (
    <TableContainer>
      <StickyHeader>
        <ColumnHeader isHighlighted>
          {formatMessage({ id: "pages.home.exdevDifference.exdev" })}
        </ColumnHeader>
        <ColumnHeader>
          {formatMessage({ id: "pages.home.exdevDifference.crowdfunding" })}
        </ColumnHeader>
        <ColumnHeader>
          {formatMessage({ id: "pages.home.exdevDifference.other" })}
        </ColumnHeader>
      </StickyHeader>
      <TableBody>{tableData.map((row) => renderRow(row))}</TableBody>
    </TableContainer>
  )
}

export default MobileDataTable

MobileDataTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      exdevHas: PropTypes.boolean,
      crowdfundingSitesHave: PropTypes.boolean,
      otherDevelopersHave: PropTypes.boolean,
    })
  ).isRequired,
}
