import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import getShowcaseItem from "utils/getShowcaseItem"
import { StyledSectionHeader } from "../HomepageCommonComponents.styles"
import VideoShowcase from "../../../molecules/VideoShowcase"
import EasyInvestment from "./EasyInvestment"
import {
  StyledSectionWrapper,
  StyledShowcaseWrapper,
} from "./SeamlessExperience.styles"

const SeamlessExpierience = ({ sectionData }) => {
  const showcaseData = [
    {
      messageId: "seamless.rapidProspecting",
      url: sectionData.videoRapidProspectingTools,
    },
    {
      messageId: "seamless.digitalSiteVisit",
      url: sectionData.videoDigitalSiteVisit,
    },
    {
      messageId: "seamless.easyInvestment",
      customComponent: (
        <EasyInvestment data={sectionData.easyInvestmentManagement[0]} />
      ),
    },
  ].map(getShowcaseItem)

  return (
    <StyledSectionWrapper id="seamless-experience">
      <StyledSectionHeader>
        <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
        <H as="h2">{sectionData.descriptiveSubheader}</H>
      </StyledSectionHeader>
      <StyledShowcaseWrapper>
        <VideoShowcase videos={showcaseData} />
      </StyledShowcaseWrapper>
    </StyledSectionWrapper>
  )
}

export default SeamlessExpierience

SeamlessExpierience.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    videoDigitalSiteVisit: PropTypes.string,
    videoRapidProspectingTools: PropTypes.string,
    easyInvestmentManagement: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
        button: PropTypes.shape({
          url: PropTypes.string,
          name: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
}
