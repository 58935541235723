import React from "react"
import PropTypes from "prop-types"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import H from "components/atoms/H"
import useWindowSize from "utils/useWindowSize"
import sortBy from "utils/sortBy"
import theme from "styles/theme"
import {
  SectionWrapper,
  SectionHeader,
  TableWrapper,
} from "./ExdevDifference.styles"
import DataTable from "./DataTable"
import MobileDataTable from "./MobileDataTable"

const ExdevDifference = ({ sectionData }) => {
  const size = useWindowSize()

  const sortedRows = sortBy(sectionData.tableRows, ["position"])

  return (
    <SectionWrapper id="exdev-difference">
      <SectionHeader>
        <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
        <H as="h2">{sectionData.descriptiveSubheader}</H>
      </SectionHeader>
      {!size || size.width >= theme.breakpoints.small ? (
        <TableWrapper>
          <DataTable tableData={sortedRows} />
        </TableWrapper>
      ) : (
        <MobileDataTable tableData={sortedRows} />
      )}
    </SectionWrapper>
  )
}

export default ExdevDifference

ExdevDifference.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    tableRows: PropTypes.arrayOf(
      PropTypes.shape({
        crowdfundingSitesHave: PropTypes.bool,
        description: PropTypes.string,
        exdevHas: PropTypes.bool,
        otherDevelopersHave: PropTypes.bool,
        position: PropTypes.number,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
}
