import React from "react"
import TrustedBrandsImage from "assets/images/trusted-brands.svg"
import { useIntl } from "gatsby-plugin-intl"
import { StyledHeading } from "./TrustedBrands.styles"

const TrustedBrands = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <StyledHeading>
        {formatMessage({ id: "pages.home.hero.trustedBrands" })}
      </StyledHeading>
      <TrustedBrandsImage />
    </>
  )
}

export default TrustedBrands
