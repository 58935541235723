import React from "react"
import times from "lodash.times"
import { useIntl } from "gatsby-plugin-intl"
import {
  BagAnimationContainer,
  StyledMoneyBag,
  InvestorText,
  InvestorWrapper,
  FlowAnimationWrapper,
  StyledInvestorIcon,
  StyledExdevIcon,
} from "./FlowAnimation.styles"

const FlowAnimation = ({ ...props }) => {
  const { formatMessage } = useIntl()
  const itemsCount = 6

  return (
    <FlowAnimationWrapper {...props}>
      <InvestorWrapper>
        <StyledInvestorIcon />
        <InvestorText>
          {formatMessage({ id: "pages.home.directInvest.investor" })}
        </InvestorText>
      </InvestorWrapper>
      <BagAnimationContainer>
        {times(itemsCount, (i) => (
          <StyledMoneyBag count={itemsCount} key={i} delay={-i} />
        ))}
      </BagAnimationContainer>
      <StyledExdevIcon />
    </FlowAnimationWrapper>
  )
}

export default FlowAnimation
