import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import HeroSection from "components/content/HomepageSection/HeroSection"
import AboutSection from "components/content/HomepageSection/AboutSection"
import CRESection from "components/content/HomepageSection/CRESection"
import BenefitSection from "components/content/HomepageSection/BenefitSection"
import FrontrowSection from "components/content/HomepageSection/FrontrowSection"
import DirectInvestingSection from "components/content/HomepageSection/DirectInvestingSection"
import SeamlessExperience from "components/content/HomepageSection/SeamlessExperienceSection"
import FAQSection from "components/content/HomepageSection/FAQSection"
import HowItWorks from "components/content/HomepageSection/HowItWorksSection"
import ExdevDifferenceSection from "components/content/HomepageSection/ExdevDifferenceSection"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"

const IndexPage = ({
  data: {
    api: { newHomepage },
  },
  pageContext: { language },
}) => (
  <Layout language={language}>
    <SEO title="Home" />
    <HeroSection
      sectionData={{
        description: newHomepage.description,
        header: newHomepage.header,
      }}
    />
    <AboutSection sectionData={newHomepage.aboutSection} />
    <CRESection sectionData={newHomepage.investBannerSection} />
    <BenefitSection sectionData={newHomepage.whyExdevSection} />
    <FrontrowSection sectionData={newHomepage.newTransparencySection} />
    <DirectInvestingSection
      sectionData={{
        directToDeveloperSection: newHomepage.directToDeveloperSection,
        heavyLiftingSection: newHomepage.heavyLiftingSection,
        animationData: newHomepage.operationsAnimationSection,
        investAtSourceSection: {
          ...newHomepage.investAtSourceSection,
          alignedGoals: newHomepage.alignedGoalsSection,
          reducedFees: newHomepage.reducedFeesSection,
          directAccessToDevelopmentTeam:
            newHomepage.directAccessToDevelopmentTeam,
          unfilteredProjectInformation:
            newHomepage.unfilteredProjectInformation,
        },
      }}
    />
    <SeamlessExperience sectionData={newHomepage.digitalExperienceSection} />
    <ExdevDifferenceSection
      sectionData={newHomepage.theExdevDifferenceSection}
    />
    <HowItWorks sectionData={newHomepage.howItWorksSection} />
    <FAQSection sectionData={newHomepage.popularQuestionSection} />
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      newHomepage: PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
        aboutSection: PropTypes.shape({
          description: PropTypes.string,
          image: PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
          }),
          partnersAndTenants: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              id: PropTypes.string,
              image: PropTypes.string,
              title: PropTypes.string,
            })
          ),
        }),
        investBannerSection: PropTypes.shape({
          description: PropTypes.string,
          mainHeader: PropTypes.string,
        }),
        whyExdevSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          transparencyHeader: PropTypes.string,
          transparencyDescription: PropTypes.string,
          directToDeveloperHeader: PropTypes.string,
          directToDeveloperDescription: PropTypes.string,
          digitalExperienceHeader: PropTypes.string,
          digitalExperienceDescription: PropTypes.string,
          ctaHeader: PropTypes.string,
          ctaSubheader: PropTypes.string,
          ctaButtonText: PropTypes.string,
        }),
        newTransparencySection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          showStory: PropTypes.bool,
          story: PropTypes.string,
          videoProgressTracking: PropTypes.string,
          videoQuarterlyReports: PropTypes.string,
          videoReports: PropTypes.string,
          videoWeeklyUpdates: PropTypes.string,
        }),
        directToDeveloperSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
        }),
        heavyLiftingSection: PropTypes.shape({
          header: PropTypes.string,
          description: PropTypes.string,
          highlightsSection: PropTypes.arrayOf(
            PropTypes.shape({
              position: PropTypes.number,
              highlight: PropTypes.string,
            })
          ),
        }),
        operationsAnimationSection: PropTypes.shape({
          processLabelSection: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
            })
          ),
        }),
        investAtSourceSection: PropTypes.shape({
          header: PropTypes.string,
          description: PropTypes.string,
        }),
        alignedGoalsSection: PropTypes.shape({
          header: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        directAccessToDevelopmentTeam: PropTypes.shape({
          header: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
        reducedFeesSection: PropTypes.shape({
          header: PropTypes.string,
          description: PropTypes.string,
        }),
        unfilteredProjectInformation: PropTypes.shape({
          header: PropTypes.string,
          description: PropTypes.string,
        }),
        digitalExperienceSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          videoDigitalSiteVisit: PropTypes.string,
          videoRapidProspectingTools: PropTypes.string,
          easyInvestmentManagement: PropTypes.arrayOf(
            PropTypes.shape({
              header: PropTypes.string,
              description: PropTypes.string,
              button: PropTypes.shape({
                url: PropTypes.string,
                name: PropTypes.string,
              }),
            })
          ),
        }),
        theExdevDifferenceSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          tableRows: PropTypes.arrayOf(
            PropTypes.shape({
              crowdfundingSitesHave: PropTypes.bool,
              description: PropTypes.string,
              exdevHas: PropTypes.bool,
              otherDevelopersHave: PropTypes.bool,
              position: PropTypes.number,
              title: PropTypes.string,
            })
          ),
        }),
        howItWorksSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          description: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          stepOneDescription: PropTypes.string,
          stepOneTitle: PropTypes.string,
          stepThreeDescription: PropTypes.string,
          stepThreeTitle: PropTypes.string,
          stepTwoDescription: PropTypes.string,
          stepTwoTitle: PropTypes.string,
        }),
        popularQuestionSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          questions: PropTypes.arrayOf(
            PropTypes.shape({
              answer: PropTypes.string,
              position: PropTypes.number,
              question: PropTypes.string,
            })
          ),
        }),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export const query = graphql`
  query IndexPageData($apiLocale: API_Locale) {
    api {
      newHomepage(language: $apiLocale) {
        description
        header
        aboutSection {
          description
          partnersAndTenants {
            description
            id
            image
            title
          }
          image {
            description
            image
            title
          }
        }
        investBannerSection {
          description
          mainHeader
        }
        whyExdevSection {
          accentMainHeader
          descriptiveSubheader
          transparencyHeader
          transparencyDescription
          directToDeveloperHeader
          directToDeveloperDescription
          digitalExperienceHeader
          digitalExperienceDescription
          ctaHeader
          ctaSubheader
          ctaButtonText
        }
        newTransparencySection {
          accentMainHeader
          descriptiveSubheader
          showStory
          story
          videoProgressTracking
          videoQuarterlyReports
          videoReports
          videoWeeklyUpdates
        }
        directToDeveloperSection {
          accentMainHeader
          descriptiveSubheader
        }
        investAtSourceSection {
          description
          header
        }
        alignedGoalsSection {
          description
          header
          image {
            description
            title
            image
          }
        }
        reducedFeesSection {
          description
          header
        }
        directAccessToDevelopmentTeam {
          description
          header
          image {
            description
            image
            title
          }
        }
        unfilteredProjectInformation {
          description
          header
        }
        heavyLiftingSection {
          description
          header
          highlightsSection {
            highlight
            position
          }
        }
        operationsAnimationSection {
          processLabelSection {
            description
          }
        }
        digitalExperienceSection {
          accentMainHeader
          descriptiveSubheader
          easyInvestmentManagement {
            description
            header
            button {
              url
              name
            }
          }
          videoDigitalSiteVisit
          videoRapidProspectingTools
        }
        theExdevDifferenceSection {
          accentMainHeader
          descriptiveSubheader
          tableRows {
            crowdfundingSitesHave
            description
            exdevHas
            otherDevelopersHave
            position
            title
          }
        }
        howItWorksSection {
          accentMainHeader
          description
          descriptiveSubheader
          stepOneDescription
          stepOneTitle
          stepThreeDescription
          stepThreeTitle
          stepTwoDescription
          stepTwoTitle
        }
        popularQuestionSection {
          accentMainHeader
          questions {
            answer
            position
            question
          }
          descriptiveSubheader
        }
      }
    }
  }
`

export default IndexPage
