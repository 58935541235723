/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import CheckMark from "assets/icons/table-check-mark.svg"
import CrossMark from "assets/icons/table-cross-mark.svg"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import {
  Table,
  TableRowHeader,
  TableColumnHeader,
  TableRowDescription,
  TableCell,
  TableRow,
} from "./DataTable.styles"

const DataTable = ({ tableData }) => {
  const { formatMessage } = useIntl()
  const renderCell = (value) => (value ? <CheckMark /> : <CrossMark />)

  const renderTableRow = (rowData) => {
    return (
      <TableRow key={rowData.position}>
        <TableRowHeader>
          {rowData.title}
          <TableRowDescription>{rowData.description}</TableRowDescription>
        </TableRowHeader>
        <TableCell>{renderCell(rowData.exdevHas)}</TableCell>
        <TableCell>{renderCell(rowData.crowdfundingSitesHave)}</TableCell>
        <TableCell>{renderCell(rowData.otherDevelopersHave)}</TableCell>
      </TableRow>
    )
  }

  return (
    <Table>
      <thead>
        <tr>
          <th />
          <TableColumnHeader isHighlighted>
            {formatMessage({ id: "pages.home.exdevDifference.exdev" })}
          </TableColumnHeader>
          <TableColumnHeader>
            {formatMessage({ id: "pages.home.exdevDifference.crowdfunding" })}
          </TableColumnHeader>
          <TableColumnHeader>
            {formatMessage({ id: "pages.home.exdevDifference.other" })}
          </TableColumnHeader>
        </tr>
      </thead>
      <tbody>{tableData.map((row) => renderTableRow(row))}</tbody>
    </Table>
  )
}

export default DataTable

DataTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      exdevHas: PropTypes.boolean,
      crowdfundingSitesHave: PropTypes.boolean,
      otherDevelopersHave: PropTypes.boolean,
    })
  ).isRequired,
}
