import styled, { css } from "styled-components"
import { learnMoreButtonStyles } from "../../HomepageCommonComponents.styles"

const StyledBenefitBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const indexStyles = css`
  color: ${({ theme }) => theme.color.secondary04};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 7.2rem;
  line-height: 1.11;
`

const headingStyles = css`
  margin-top: 1.2rem;
`

const paragraphStyles = css`
  margin-top: 2.4rem;
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};

  ${({ theme }) => theme.mq.medium} {
    margin-bottom: 2.4rem;
  }
`

const buttonStyles = css`
  ${learnMoreButtonStyles}

  margin-top: 2.4rem;

  ${({ theme }) => theme.mq.medium} {
    margin-top: auto;
  }
`

export {
  indexStyles,
  headingStyles,
  paragraphStyles,
  buttonStyles,
  StyledBenefitBlockWrapper,
}
