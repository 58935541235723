import styled, { css } from "styled-components"

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.8rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: 12rem;
    display: grid;
    grid-template-areas:
      "heading ."
      "paragraph paragraph"
      "image-left image-right"
      ". caption";
  }
`

const LeftAnimationWrapper = styled.div`
  margin-top: 2.4rem;
  text-align: center;

  ${({ theme }) => theme.mq.large} {
    padding-top: 6rem;
    margin-top: 5rem;
    grid-area: image-left;
  }
`

const AnimationContainer = styled.div`
  ${({ theme }) => theme.mq.large} {
    display: flex;
    align-items: center;
  }
`

const RightAnimationWrapper = styled.div`
  text-align: center;
  padding-top: 2.4rem;
  background: ${({ theme }) => theme.color.white};
  clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0 100%, 0 20%);

  ${({ theme }) => theme.mq.large} {
    padding-top: 6rem;
    margin-top: 5rem;
    grid-area: image-right;
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%, 0 20%);
  }
`
const AnimationCaption = styled.p`
  color: ${({ theme }) => theme.color.secondary02};
  line-height: 1.71;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.large} {
    margin-top: 1.6rem;
    text-align: center;
    grid-area: caption;
  }
`

const animationHeadingCommon = css`
  font-size: 4.8rem;

  ${({ theme }) => theme.mq.large} {
    font-size: 7.2rem;
  }
`

const animationLeftHeading = css`
  ${animationHeadingCommon}
  color: ${({ theme }) => theme.color.secondary03};
`

const animationRightHeading = css`
  ${animationHeadingCommon}
`

const animationParagraph = css`
  margin-top: 2.4rem;
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};
  order: 3;

  ${({ theme }) => theme.mq.large} {
    margin-top: 1.6rem;
    grid-area: paragraph;
    max-width: 60%;
  }
`

const animationBlockHeading = css`
  ${({ theme }) => theme.mq.large} {
    grid-area: heading;
  }
`

export {
  AnimationWrapper,
  LeftAnimationWrapper,
  RightAnimationWrapper,
  AnimationCaption,
  animationLeftHeading,
  animationRightHeading,
  animationBlockHeading,
  animationParagraph,
  AnimationContainer,
}
