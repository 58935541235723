import styled, { css } from "styled-components"
import StepList from "./StepList"

const SectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.secondary05};
`

const ContentWrapper = styled.div`
  padding: 8rem 1.6rem;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.small} {
    gap: 13rem;
    flex-direction: row;
    align-items: center;

    padding: 12rem 1.6rem 15rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`

const MainContent = styled.div`
  order: 1;

  ${({ theme }) => theme.mq.small} {
    max-width: 50%;
    order: 2;
  }
`

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  margin: 1.6rem 0rem 4.8rem;

  ${({ theme }) => theme.mq.small} {
    margin: 1.6rem 0rem 3.2rem;
  }
`

const StyledStepList = styled(StepList)`
  order: 2;
`

export {
  MainContent,
  SectionWrapper,
  ContentWrapper,
  paragraphStyles,
  StyledStepList,
}
