import styled, { css } from "styled-components"
import Image from "components/atoms/Image"

const imageParagraph = css`
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};
  order: 3;

  ${({ theme }) => theme.mq.large} {
    margin-top: 1.6rem;
    grid-area: paragraph;
  }
`

const imageHeading = css`
  order: 1;
  margin-bottom: 1.6rem;

  ${({ theme }) => theme.mq.large} {
    margin-bottom: 0rem;
    grid-area: heading;
    align-self: end;
  }
`

const ContentWrapper = styled.div`
  margin-top: 4.8rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: 10rem;
  }
`
const ImageBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${({ theme }) => theme.mq.large} {
    display: grid;
    column-gap: 10rem;
    row-gap: 0rem;
    grid-template-areas:
      "image heading"
      "image paragraph";
  }
`

const StyledImage = styled(Image)`
  max-width: 50rem;
  order: 2;

  ${({ theme }) => theme.mq.large} {
    grid-area: image;
  }
`

export {
  imageParagraph,
  imageHeading,
  ContentWrapper,
  ImageBlockWrapper,
  StyledImage,
}
