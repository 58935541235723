import styled, { css } from "styled-components"

const StyledFrontrowWrapper = styled.section`
  overflow: hidden;
  padding: 8rem 1.6rem 7rem;

  ${({ theme }) => theme.mq.large} {
    padding: 12rem 1.6rem 5rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 0.4rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6.5rem;
  }
`

const StyledShowcaseWrapper = styled.div`
  margin-top: 4.8rem;
`

const paragraphStyles = css`
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};

  ${({ theme }) => theme.mq.medium} {
    max-width: 50%;
  }
`

const AnimationWrapper = styled.div`
  max-width: 45rem;
  max-height: 33rem;
`

export {
  StyledFrontrowWrapper,
  StyledContent,
  paragraphStyles,
  StyledShowcaseWrapper,
  AnimationWrapper,
}
