import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import sortBy from "utils/sortBy"
import { StyledSectionHeader } from "../HomepageCommonComponents.styles"
import FAQAccordion from "./FAQAccordion"
import { SectionWrapper, ContentWrapper } from "./FAQSection.styles"

const FAQSection = ({ sectionData }) => {
  const sortedQuestions = sortBy(sectionData.questions, ["position"])

  return (
    <SectionWrapper id="popular-questions">
      <StyledSectionHeader>
        <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
        <H as="h2">{sectionData.descriptiveSubheader}</H>
      </StyledSectionHeader>
      <ContentWrapper>
        <FAQAccordion data={sortedQuestions} />
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default FAQSection

FAQSection.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string,
        position: PropTypes.number,
        question: PropTypes.string,
      })
    ),
  }).isRequired,
}
