import React from "react"
import PropTypes from "prop-types"
import SlidingAnimation from "./SlidingAnimation"
import {
  AnimationWrapper,
  AnimationMask,
  StyledFlowAnimation,
} from "./HeavyLiftingAnimation.styles"

const HeavyLiftingAnimation = ({ labels }) => {
  return (
    <AnimationWrapper>
      <StyledFlowAnimation />
      <AnimationMask />
      <SlidingAnimation labels={labels} />
    </AnimationWrapper>
  )
}

export default HeavyLiftingAnimation

HeavyLiftingAnimation.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
}
