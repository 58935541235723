import styled from "styled-components"

const SectionWrapper = styled.section`
  padding-bottom: 8rem;
  background-color: ${({ theme }) => theme.color.secondary05};

  ${({ theme }) => theme.mq.large} {
    padding-bottom: 12rem;
  }
`

const ContentWrapper = styled.div`
  padding: 8rem 1.6rem 0rem;
  max-width: ${({ theme }) => theme.gridWidth};
  margin: 0 auto;

  ${({ theme }) => theme.mq.large} {
    padding: 12rem 1.6rem 0rem;
  }
`

export { SectionWrapper, ContentWrapper }
