import styled from "styled-components"

const StyledBenefitSection = styled.section`
  padding: 8rem 1.6rem 0rem;

  ${({ theme }) => theme.mq.large} {
    padding: 12rem 1.6rem 0rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`

const StyledBenefitBlocksWrapper = styled.div`
  margin-top: 4rem;

  display: flex;
  flex-direction: column;
  gap: 5.4rem;

  justify-content: space-between;
  align-items: stretch;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    gap: 3.2rem;
  }
`

export { StyledBenefitSection, StyledBenefitBlocksWrapper }
