import React, { useState } from "react"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import Plus from "assets/icons/plus.svg"
import Minus from "assets/icons/minus.svg"
import {
  AccordionItem,
  AccordionButton,
  contentStyles,
  AccordionWrapper,
  IconWrapper,
} from "./FAQAccordion.styles"

const FAQAccordion = ({ data }) => {
  const [openItem, setOpenItem] = useState()

  const handleClick = (index) => {
    if (openItem === index) {
      setOpenItem(null)
      return
    }

    setOpenItem(index)
  }

  return (
    <AccordionWrapper>
      {data.map((item, index) => {
        const isCurrentItem = openItem === index

        return (
          <AccordionItem key={item.question}>
            <AccordionButton onClick={() => handleClick(index)}>
              {item.question}
              <IconWrapper>{isCurrentItem ? <Minus /> : <Plus />}</IconWrapper>
            </AccordionButton>
            {isCurrentItem && <P extraStyles={contentStyles}>{item.answer}</P>}
          </AccordionItem>
        )
      })}
    </AccordionWrapper>
  )
}

export default FAQAccordion

FAQAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ).isRequired,
}
