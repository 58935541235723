import styled, { css } from "styled-components"

const contentStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.mq.small} {
    margin-bottom: 4.8rem;
  }
`

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const AccordionButton = styled.button`
  padding: 2rem 0;
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary01};

  background: none;
  border: none;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  &:active {
    color: ${({ theme }) => theme.color.secondary01};
  }

  svg {
    margin-left: 1.8rem;
    width: 14px;
    height: 14px;

    flex-shrink: 0;
  }
`
const AccordionItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};

  &:last-of-type {
    border-bottom: none;
  }
`

const IconWrapper = styled.span``

export {
  AccordionItem,
  AccordionButton,
  contentStyles,
  AccordionWrapper,
  IconWrapper,
}
