import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import H from "components/atoms/H"
import P from "components/atoms/P"
import Promotion from "assets/animations/promotion.json"
import lottie from "lottie-web"
import getShowcaseItem from "utils/getShowcaseItem"
import { useInView } from "react-intersection-observer"
import {
  StyledFrontrowWrapper,
  StyledContent,
  paragraphStyles,
  StyledShowcaseWrapper,
  AnimationWrapper,
} from "./FrontrowSection.styles"
import { StyledSectionHeader } from "../HomepageCommonComponents.styles"
import VideoShowcase from "../../../molecules/VideoShowcase"

const FrontrowSection = ({ sectionData }) => {
  const animationContainer = useRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const [anim, setAnim] = useState(null)

  const showcaseData = [
    { messageId: "frontrow.videoReports", url: sectionData.videoReports },
    {
      messageId: "frontrow.weeklyUpdates",
      url: sectionData.videoWeeklyUpdates,
    },
    {
      messageId: "frontrow.progressTracking",
      url: sectionData.videoProgressTracking,
    },
    {
      messageId: "frontrow.quaterlyReports",
      url: sectionData.videoQuarterlyReports,
    },
  ].map(getShowcaseItem)

  useEffect(() => {
    const hero = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: Promotion,
    })
    setAnim(hero)
    return () => hero.destroy()
  }, [])

  useEffect(() => {
    if (anim && anim.isPaused && inView) {
      anim.playSegments(0, true)
    }
  }, [inView])

  return (
    <StyledFrontrowWrapper id="frontrow-reporting">
      <StyledSectionHeader>
        <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
        <H as="h2">{sectionData.descriptiveSubheader}</H>
      </StyledSectionHeader>
      {sectionData.showStory && (
        <StyledContent>
          <P extraStyles={paragraphStyles}>{sectionData.story}</P>
          <AnimationWrapper ref={ref}>
            <div ref={animationContainer} />
          </AnimationWrapper>
        </StyledContent>
      )}
      <StyledShowcaseWrapper showStory={sectionData.showStory}>
        <VideoShowcase videos={showcaseData} />
      </StyledShowcaseWrapper>
    </StyledFrontrowWrapper>
  )
}

export default FrontrowSection

FrontrowSection.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    showStory: PropTypes.bool,
    story: PropTypes.string,
    videoProgressTracking: PropTypes.string,
    videoQuarterlyReports: PropTypes.string,
    videoReports: PropTypes.string,
    videoWeeklyUpdates: PropTypes.string,
  }).isRequired,
}
