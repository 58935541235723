import MoneyBag from "assets/images/money-bag.svg"
import Investor from "assets/images/investor-icon.svg"
import Exdev from "assets/images/exdev-icon.svg"

import styled, { keyframes, css } from "styled-components"

const moneyFlow = (count) => keyframes`
0% {
    transform: translateX(${count * 1.8}rem);
}

100% {
    transform: translateX(-${1.4}rem);
}
`

const pulse = keyframes`
0% {
    transform: scale(1);
}
10% {
    transform: scale(1.1)

}
20% {
    transform: scale(1)

}
`

const pulseAnimation = css`
  animation: ${pulse};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 8s;
`

const FlowAnimationWrapper = styled.div`
  display: flex;
  align-items: center;
`

const InvestorWrapper = styled.div`
  position: relative;
  z-index: 3;
`

const InvestorText = styled.span`
  position: absolute;
  white-space: nowrap;

  text-transform: uppercase;
  color: ${({ theme }) => theme.color.septenary03};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  bottom: -3.2rem;
  left: 50%;
  transform: translateX(-50%);
`

const StyledMoneyBag = styled(MoneyBag)`
  position: absolute;
  z-index: 0;

  animation-name: ${({ count }) => moneyFlow(count)};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: ${({ count }) => count / 2}s;

  ${({ delay }) =>
    delay &&
    css`
      animation-delay: ${delay / 2}s;
    `}
`

const StyledInvestorIcon = styled(Investor)`
  ${pulseAnimation}
  animation-delay: 0.5s;
`

const StyledExdevIcon = styled(Exdev)`
  z-index: 3;
  ${pulseAnimation}
`

const BagAnimationContainer = styled.div`
  overflow: hidden;
  height: 2rem;
  width: 6.6rem;

  ${({ theme }) => theme.mq.medium} {
    width: 10rem;
  }
`

export {
  BagAnimationContainer,
  StyledMoneyBag,
  InvestorText,
  InvestorWrapper,
  FlowAnimationWrapper,
  StyledInvestorIcon,
  StyledExdevIcon,
}
