import React from "react"
import H from "components/atoms/H"
import P from "components/atoms/P"
import PropTypes from "prop-types"
import {
  ContentWrapper,
  ImageBlockWrapper,
  imageHeading,
  imageParagraph,
  StyledImage,
} from "./InvestContentBlock.styles"
import AnimationBlock from "./AnimationBlock"

const InvestContentBlock = ({
  animationType,
  sectionData,
  showAnimationCaption,
}) => {
  const { firstParagraph, secondParagraph } = sectionData

  return (
    <ContentWrapper>
      <ImageBlockWrapper>
        <StyledImage
          src={firstParagraph.image.image}
          alt={firstParagraph.image.title}
        />
        <H as="h4" extraStyles={imageHeading}>
          {firstParagraph.header}
        </H>
        <P extraStyles={imageParagraph}>{firstParagraph.description}</P>
      </ImageBlockWrapper>
      <AnimationBlock
        animationType={animationType}
        sectionData={secondParagraph}
        showCaption={showAnimationCaption}
      />
    </ContentWrapper>
  )
}

export default InvestContentBlock

InvestContentBlock.propTypes = {
  animationType: PropTypes.oneOf(["REDUCED_FEES", "DIRECT_ACCESS"]).isRequired,
  sectionData: PropTypes.shape({
    firstParagraph: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    secondParagraph: PropTypes.shape({
      header: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  showAnimationCaption: PropTypes.bool,
}

InvestContentBlock.defaultProps = {
  showAnimationCaption: true,
}
