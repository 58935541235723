import React from "react"
import PropTypes from "prop-types"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import H from "components/atoms/H"
import P from "components/atoms/P"
import Button from "components/atoms/Button"
import useWindowSize from "utils/useWindowSize"
import { useIntl } from "gatsby-plugin-intl"
import theme from "styles/theme"
import BtnLink from "components/atoms/BtnLink"
import paths from "utils/paths"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import {
  SectionWrapper,
  ContentWrapper,
  paragraphStyles,
  MainContent,
  StyledStepList,
} from "./HowItWorks.styles"

const HowItWorks = ({ sectionData }) => {
  const { formatMessage } = useIntl()
  const size = useWindowSize()
  const { getStarted } = paths
  const isMobile = !size || size.width < theme.breakpoints.small

  const steps = [
    {
      title: sectionData.stepOneTitle,
      description: sectionData.stepOneDescription,
    },
    {
      title: sectionData.stepTwoTitle,
      description: sectionData.stepTwoDescription,
    },
    {
      title: sectionData.stepThreeTitle,
      description: sectionData.stepThreeDescription,
    },
  ]

  return (
    <SectionWrapper id="how-it-works">
      <ContentWrapper>
        <StyledStepList steps={steps} isMobile={isMobile} />
        <MainContent>
          <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
          <H as="h2">{sectionData.descriptiveSubheader}</H>
          <P extraStyles={paragraphStyles}>{sectionData.description}</P>
          {!isMobile && (
            <BtnLink to={getStarted}>
              <Button
                trackingOptions={{
                  category: trackingCategoriesHelper.HOME,
                  action: "Click",
                  label: `${trackingCategoriesHelper.HOME} howItWorks link(register)`,
                }}
              >
                {formatMessage({ id: "pages.home.howItWorks.button" })}
              </Button>
            </BtnLink>
          )}
        </MainContent>
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default HowItWorks

HowItWorks.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    description: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    stepOneDescription: PropTypes.string,
    stepOneTitle: PropTypes.string,
    stepThreeDescription: PropTypes.string,
    stepThreeTitle: PropTypes.string,
    stepTwoDescription: PropTypes.string,
    stepTwoTitle: PropTypes.string,
  }).isRequired,
}
