import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import Button from "components/atoms/Button"

import {
  paragraphStyles,
  buttonStyles,
  StyledTopImage,
  StyledBottomImage,
  ContentWrapper,
  headerStyles,
} from "./EasyInvestment.styles"

const EasyInvestment = ({ data }) => {
  return (
    <ContentWrapper>
      <StyledTopImage />
      <H as="h4" extraStyles={headerStyles}>
        {data.header}
      </H>
      <P extraStyles={paragraphStyles}>{data.description}</P>
      <Button
        extraStyles={buttonStyles}
        type="secondaryFilled"
        size="medium"
        to={data.button.url}
        trackingOptions={{
          category: trackingCategoriesHelper.HOME,
          action: "Click",
          label: `${trackingCategoriesHelper.HOME} easy investment link(start now)`,
        }}
      >
        {data.button.name}
      </Button>
      <StyledBottomImage />
    </ContentWrapper>
  )
}

export default EasyInvestment

EasyInvestment.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
}
