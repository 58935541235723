import React, { useState } from "react"
import PropTypes from "prop-types"
import SideListItem from "components/atoms/SideListItem/index"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import P from "components/atoms/P"
import { useIntl } from "gatsby-plugin-intl"

import {
  SectionWrapper,
  SidelistWrapper,
  customStageListStyles,
  customContentStyles,
  ContentWrapper,
  BrowserWrapper,
  PlayerWrapper,
  StyledReactPlayer,
  videoDescriptionStyles,
  StyledHeading,
  StyledParagraph,
  StyledBrowserBar,
} from "./VideoShowcase.styles"

const VideoShowcase = ({ videos }) => {
  const { formatMessage } = useIntl()
  const [activeStage, setActiveStage] = useState(videos[0])

  return (
    <SectionWrapper>
      <SidelistWrapper>
        {videos.map((item) => {
          const isActive = activeStage.title === item.title

          return (
            <SideListItem
              isActive={isActive}
              onClick={() => {
                setActiveStage(item)
                trackCustomEventTrigger({
                  category: trackingCategoriesHelper.HOME,
                  action: "Click",
                  label: `${trackingCategoriesHelper.HOME} link(${item.title})`,
                })
              }}
              key={item.title}
              extraStyles={customStageListStyles}
              contentStyles={customContentStyles}
              showIcon={false}
            >
              <StyledHeading isActive={isActive} as="h3">
                {item.title}
              </StyledHeading>
              <StyledParagraph isActive={isActive}>
                {item.description}
              </StyledParagraph>
            </SideListItem>
          )
        })}
      </SidelistWrapper>
      <ContentWrapper>
        <BrowserWrapper>
          <StyledBrowserBar />
          <PlayerWrapper>
            {activeStage.customComponent ? (
              <>{activeStage.customComponent}</>
            ) : (
              <StyledReactPlayer
                url={activeStage.videoUrl}
                width="100%"
                height="100%"
                playing
                loop
                muted
                playsinline
              />
            )}
          </PlayerWrapper>
        </BrowserWrapper>
        <P extraStyles={videoDescriptionStyles}>
          {formatMessage({ id: "pages.home.showcaseCaption" })}
        </P>
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default VideoShowcase

VideoShowcase.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      videoUrl: PropTypes.string,
    })
  ).isRequired,
}
