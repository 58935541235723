import styled, { css } from "styled-components"

const TableContainer = styled.div`
  position: relative;
  margin-top: 1.6rem;
`

const ColumnHeader = styled.div`
  padding: 2rem 0;
  width: 33%;
  text-align: center;

  color: ${({ theme }) => theme.color.secondary02};
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      color: ${theme.color.septenary03};
      font-weight: ${theme.font.weight.semiBold};
    `}
`

const StickyHeader = styled.div`
  position: sticky;
  top: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
`

const RowWrapper = styled.div`
  padding: 1.6rem 0rem 2.4rem;

  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};

  &:last-of-type {
    border-bottom: none;
  }
`
const RowChecks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 16px;
`
const TableCell = styled.div`
  width: 33%;
  text-align: center;
`
const TableBody = styled.div`
  padding: 0rem 1.6rem;
`

const headingStyles = css`
  color: ${({ theme }) => theme.color.secondary01};
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
  margin-top: 0.8rem;
`

export {
  TableContainer,
  ColumnHeader,
  StickyHeader,
  RowChecks,
  RowWrapper,
  TableCell,
  TableBody,
  headingStyles,
  paragraphStyles,
}
