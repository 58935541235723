import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import BtnLink from "components/atoms/BtnLink"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import Image from "components/atoms/Image"
import LogoCarousel from "components/molecules/LogoCarousel"
import chunkArray from "utils/chunkArray"
import { useIntl } from "gatsby-plugin-intl"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import ArrowRight from "assets/icons/arrow-right.svg"
import theme from "styles/theme"
import paths from "utils/paths"

import {
  StyledAboutSection,
  StyledContentWrapper,
  StyledImage,
  paragraphStyles,
  StyledLogo,
  StyledLogosContainer,
  StyledLogosWrapper,
  StyledTextWrapper,
  buttonStyles,
  imageHeadingStyles,
  StyledSectionHeader,
} from "./HomepageAboutSections.styles"

const HomepageAboutSection = ({ sectionData }) => {
  const { formatMessage } = useIntl()
  const { partnersAndTenants, image, description } = sectionData
  const { about } = paths

  const createLogoSection = () => {
    const imagesLength = partnersAndTenants.length
    if (imagesLength < 5) {
      return partnersAndTenants.map((img) => (
        <StyledLogo key={img.id}>
          <Image src={img.image} alt={img.id} />
        </StyledLogo>
      ))
    }

    if (imagesLength < 10) {
      return (
        <LogoCarousel
          images={partnersAndTenants}
          customTileColor={theme.color.secondary07}
        />
      )
    }

    const chunkedImages = chunkArray(partnersAndTenants, 2)

    return (
      <>
        <LogoCarousel
          images={chunkedImages[0]}
          delay={3 - (imagesLength % 3)}
          customTileColor={theme.color.secondary07}
        />
        <LogoCarousel
          images={chunkedImages[1]}
          customTileColor={theme.color.secondary07}
        />
      </>
    )
  }

  return (
    <>
      <StyledAboutSection>
        <StyledSectionHeader id="homepage-about">
          <SectionSubtitle>
            {formatMessage({
              id: `pages.home.about.subtitle`,
            })}
          </SectionSubtitle>
          <H as="h2">
            {formatMessage({
              id: `pages.home.about.title`,
            })}
          </H>
        </StyledSectionHeader>
        <StyledContentWrapper>
          <StyledTextWrapper>
            <H as="h3" extraStyles={imageHeadingStyles}>
              {formatMessage({
                id: `pages.home.about.imageTitle`,
              })}
            </H>
            <P extraStyles={paragraphStyles}>{description}</P>
            <BtnLink
              extraStyles={buttonStyles}
              to={about}
              trackingOptions={{
                category: trackingCategoriesHelper.HOME,
                action: "Click",
                label: `${trackingCategoriesHelper.HOME} link(learn more)`,
              }}
            >
              {formatMessage({
                id: `pages.home.about.button`,
              })}
              <ArrowRight />
            </BtnLink>
          </StyledTextWrapper>
          <StyledImage src={image.image} alt={image.title} />
        </StyledContentWrapper>
        <StyledLogosWrapper withBackground={partnersAndTenants.length < 5}>
          <StyledLogosContainer>{createLogoSection()}</StyledLogosContainer>
        </StyledLogosWrapper>
      </StyledAboutSection>
    </>
  )
}

export default HomepageAboutSection

HomepageAboutSection.propTypes = {
  sectionData: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
    }),
    partnersAndTenants: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
}
