import React from "react"
import PropTypes from "prop-types"
import Button from "components/atoms/Button"
import { useIntl } from "gatsby-plugin-intl"

import { StyledList, buttonStyles } from "./StepList.styles"
import ListItem from "./ListItem"

const StepList = ({ steps, isMobile, ...rest }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <StyledList {...rest}>
        {steps.map((step, index) => (
          <ListItem
            key={step.title}
            index={`0${index + 1}`}
            title={step.title}
            description={step.description}
          />
        ))}
      </StyledList>
      {isMobile && (
        <Button extraStyles={buttonStyles}>
          {formatMessage({ id: "pages.home.howItWorks.button" })}
        </Button>
      )}
    </>
  )
}

export default StepList

StepList.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  isMobile: PropTypes.bool,
}

StepList.defaultProps = {
  isMobile: false,
}
