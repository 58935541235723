import React from "react"
import H from "components/atoms/H"
import P from "components/atoms/P"
import PropTypes from "prop-types"
import {
  StyledListItem,
  listNumberStyle,
  titleStyle,
  paragraphStyles,
} from "./StepList.styles"

const ListItem = ({ index, title, description }) => {
  return (
    <StyledListItem>
      <H extraStyles={listNumberStyle} as="h3">
        {index}
      </H>
      <H as="h4" extraStyles={titleStyle}>
        {title}
      </H>
      <P extraStyles={paragraphStyles}>{description}</P>
    </StyledListItem>
  )
}

export default ListItem

ListItem.propTypes = {
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
