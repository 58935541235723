import React, { useMemo, Fragment } from "react"
import chunkArray from "utils/chunkArray"
import { PropTypes } from "prop-types"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"
import times from "lodash.times"
import {
  positions,
  colorSchemas,
  SlidingAnimation,
  AnimationWrapper,
  AnimationSectionWrapper,
  AnimationItem,
} from "./SlidingAnimation.styles"

const HeavyLiftingSlidingAnimation = ({ labels }) => {
  const size = useWindowSize()
  const slidesCount = Math.ceil(size.width / theme.breakpoints.extraLarge)

  const chunks = chunkArray(
    labels,
    Math.ceil(labels.length / positions.length)
  ).reverse()

  const renderSlide = (chunk) => {
    return (
      <AnimationSectionWrapper key={chunk[0]}>
        {chunk.map((item, index) => {
          const position = positions[index]
          const colorSchema =
            colorSchemas[Math.floor(Math.random() * colorSchemas.length)]

          return (
            <AnimationItem
              key={item}
              position={position}
              colorSchema={colorSchema}
            >
              <span>{item}</span>
            </AnimationItem>
          )
        })}
      </AnimationSectionWrapper>
    )
  }

  const slidesToRender = useMemo(
    () => chunks.map((chunk) => renderSlide(chunk)),
    []
  )

  return (
    <SlidingAnimation>
      <AnimationWrapper count={chunks.length}>
        {times(slidesCount, (i) => (
          <Fragment key={i}>
            {slidesToRender}
            {slidesToRender[0]}
          </Fragment>
        ))}
      </AnimationWrapper>
    </SlidingAnimation>
  )
}

HeavyLiftingSlidingAnimation.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default HeavyLiftingSlidingAnimation
