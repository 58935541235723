import React from "react"
import H from "components/atoms/H"
import P from "components/atoms/P"
import BtnLink from "components/atoms/BtnLink"
import PropTypes from "prop-types"
import ArrowRight from "assets/icons/arrow-right.svg"
import { useIntl } from "gatsby-plugin-intl"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import {
  StyledBenefitBlockWrapper,
  indexStyles,
  headingStyles,
  paragraphStyles,
  buttonStyles,
} from "./BenefitBlock.styles"

const BenefitBlock = ({ index, title, description, link }) => {
  const { formatMessage } = useIntl()

  return (
    <StyledBenefitBlockWrapper>
      <H extraStyles={indexStyles} as="h3">
        {index}
      </H>
      <H extraStyles={headingStyles} as="h4">
        {title}
      </H>
      <P extraStyles={paragraphStyles}>{description}</P>
      <BtnLink
        extraStyles={buttonStyles}
        to={link}
        trackingOptions={{
          category: trackingCategoriesHelper.HOME,
          action: "Click",
          label: `${trackingCategoriesHelper.HOME} benefits link(learn more)`,
        }}
      >
        {formatMessage({ id: "pages.home.benefits.linkText" })} <ArrowRight />
      </BtnLink>
    </StyledBenefitBlockWrapper>
  )
}

BenefitBlock.propTypes = {
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
}

BenefitBlock.defaultProps = {
  link: "",
}

export default BenefitBlock
