import styled, { css } from "styled-components"
import Image from "components/atoms/Image"
import { learnMoreButtonStyles } from "../HomepageCommonComponents.styles"

const StyledAboutSection = styled.section`
  position: relative;
  padding: 7.2rem 1.6rem 0rem;

  ${({ theme }) => theme.mq.medium} {
    padding: 12rem 1.6rem 0rem;
  }

  ${({ theme }) => theme.mq.large} {
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
    padding: 30rem 1.6rem 0rem;
  }
`

const StyledSectionHeader = styled.div`
  text-align: left;

  ${({ theme }) => theme.mq.large} {
    text-align: center;

    max-width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
  }
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mq.large} {
    margin-top: 10rem;

    flex-direction: row;
    align-items: center;
    gap: 10rem;
  }
`

const StyledImage = styled(Image)`
  max-width: 50rem;
  width: 100%;
  object-fit: contain;
  flex-shrink: 0;

  margin-top: 3.2rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: 0rem;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 3.2rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: 0rem;
  }
`

const StyledLogosWrapper = styled.div`
  margin-top: 4.2rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: 10rem;
  }
`

const StyledLogosContainer = styled.div`
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledLogo = styled.div`
  width: 100%;
  max-width: calc(50% - 1.6rem);
  height: 9.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 65%;
    height: 40%;

    img {
      object-fit: contain !important;
    }
  }

  ${({ theme }) => theme.mq.medium} {
    max-width: 24%;
    height: 16.8rem;

    .gatsby-image-wrapper {
      width: 80%;
      height: 45%;
    }
  }

  ${({ theme }) => theme.mq.large} {
    max-width: 16.8rem;
  }
`

const paragraphStyles = css`
  margin-top: 1.6rem;
  line-height: 1.77;
  font-size: ${({ theme }) => theme.font.size.l};

  ${({ theme }) => theme.mq.medium} {
    margin-top: 2.4rem;
  }
`

const buttonStyles = css`
  ${learnMoreButtonStyles}

  ${({ theme }) => theme.mq.large} {
    margin-top: 3rem;
  }

  svg {
    margin-left: 1rem;
  }
`

const imageHeadingStyles = css`
  font-size: ${({ theme }) => theme.font.size.xl};

  ${({ theme }) => theme.mq.medium} {
    font-size: 3.2rem;
  }
`

export {
  StyledAboutSection,
  StyledSectionHeader,
  StyledContentWrapper,
  StyledImage,
  paragraphStyles,
  buttonStyles,
  StyledTextWrapper,
  StyledLogo,
  StyledLogosContainer,
  StyledLogosWrapper,
  imageHeadingStyles,
}
