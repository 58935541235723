import styled, { css } from "styled-components"

import TreeBuilding from "assets/images/tree-building.svg"
import BuildingBlock from "assets/images/building-block.svg"

const paragraphStyles = css`
  margin-top: 2.4rem;
  padding: 0 2.4rem;
  z-index: 1;
`
const buttonStyles = css`
  margin-top: 4rem;
`
const headerStyles = css`
  padding: 0 2.4rem;
  z-index: 1;
`

const StyledTopImage = styled(BuildingBlock)`
  position: absolute;
  top: 0;
  left: 0;

  height: 12rem;
  width: 8rem;
`

const StyledBottomImage = styled(TreeBuilding)`
  position: absolute;
  bottom: 0;
  right: 0;

  height: 6rem;
  width: 8rem;
`

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.color.white};
`

export {
  paragraphStyles,
  buttonStyles,
  StyledTopImage,
  StyledBottomImage,
  ContentWrapper,
  headerStyles,
}
