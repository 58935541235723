import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import BtnLink from "components/atoms/BtnLink"
import Button from "components/atoms/Button"
import paths from "utils/paths"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import { useIntl } from "gatsby-plugin-intl"
import WhyExdevAnimation from "assets/animations/why-exdev-animation.json"
import lottie from "lottie-web"
import { useInView } from "react-intersection-observer"

import {
  StyledCRESection,
  StyledContentWrapper,
  StyledTextWrapper,
  StyledImage,
  headingStyles,
  paragraphStyles,
  buttonStyles,
  ImageWrapper,
} from "./HomepageCRESection.styles"

const HomepageCRESection = ({ sectionData }) => {
  const { getStarted } = paths
  const { formatMessage } = useIntl()
  const { description, mainHeader } = sectionData
  const animationContainer = useRef()
  const [anim, setAnim] = useState(null)
  const [animIdle, setAnimIdle] = useState(false)
  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    const hero = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: WhyExdevAnimation,
    })
    setAnim(hero)
    return () => hero.destroy()
  }, [])

  useEffect(() => {
    if (anim && anim.isPaused && inView && !animIdle) {
      anim.playSegments([[0, 360]], true)
      setAnimIdle(true)
    }
  }, [inView])

  useEffect(() => {
    let timer
    if (animIdle) {
      timer = setTimeout(() => {
        setAnimIdle(false)
      }, 45000)
    }
    return () => clearTimeout(timer)
  }, [animIdle])

  return (
    <StyledCRESection id="investInCRE">
      <StyledContentWrapper>
        <ImageWrapper ref={ref}>
          <StyledImage ref={animationContainer} />
        </ImageWrapper>
        <StyledTextWrapper>
          <H extraStyles={headingStyles} as="h2">
            {mainHeader}
          </H>
          <P extraStyles={paragraphStyles}>{description}</P>
          <BtnLink to={getStarted}>
            <Button
              type="white"
              extraStyles={buttonStyles}
              trackingOptions={{
                category: trackingCategoriesHelper.HOME,
                action: "Click",
                label: `${trackingCategoriesHelper.HOME} link(register)`,
              }}
            >
              {formatMessage({
                id: "pages.home.invest.button",
              })}
            </Button>
          </BtnLink>
        </StyledTextWrapper>
      </StyledContentWrapper>
    </StyledCRESection>
  )
}

export default HomepageCRESection

HomepageCRESection.propTypes = {
  sectionData: PropTypes.shape({
    description: PropTypes.string,
    mainHeader: PropTypes.string,
  }).isRequired,
}
