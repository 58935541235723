import styled from "styled-components"

const StyledHeading = styled.span`
  display: block;
  margin-bottom: 0.8rem;

  color: ${({ theme }) => theme.color.secondary03};
  font-size: ${({ theme }) => theme.font.size.s};
`

export { StyledHeading }
