import styled, { css } from "styled-components"

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  margin-top: 1.6rem;

  ${({ theme }) => theme.mq.small} {
    max-width: 80%;
  }
`

const StyledHeroSection = styled.section`
  position: relative;
  padding: 25rem 1.6rem 0rem;

  ${({ theme }) => theme.mq.small} {
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
    padding: 12rem 1.6rem 0rem;
  }
`

const SectionContentWrapper = styled.div`
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mq.small} {
    max-width: 60%;
  }
`

const StyledButtonWrapper = styled.div`
  margin-top: 1.6rem;
`

const TrustedBrandsWrapper = styled.div`
  margin-top: 1.6rem;
  max-width: 32rem;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 4rem;
  }
`

const StyledHeroAnimation = styled.div`
  min-height: 45rem;
  min-width: 55rem;

  position: absolute;
  top: -10rem;
  right: 0;

  ${({ theme }) => theme.mq.small} {
    top: 0;
    right: -5%;
  }

  ${({ theme }) => theme.mq.medium} {
    right: 0;
  }
`

export {
  StyledHeroSection,
  SectionContentWrapper,
  paragraphStyles,
  StyledButtonWrapper,
  TrustedBrandsWrapper,
  StyledHeroAnimation,
}
