import styled from "styled-components"

const SectionWrapper = styled.section`
  padding: 8rem 0rem 7rem;

  ${({ theme }) => theme.mq.large} {
    padding: 14rem 1.6rem 17rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`
const SectionHeader = styled.div`
  padding: 0rem 1.6rem;

  ${({ theme }) => theme.mq.small} {
    max-width: 60%;
  }
`

const TableWrapper = styled.div`
  padding: 0rem 1.6rem;
  margin-top: 2.4rem;
`

export { SectionWrapper, SectionHeader, TableWrapper }
