import styled from "styled-components"

const StyledSectionWrapper = styled.section`
  padding: 8rem 1.6rem 0rem;

  ${({ theme }) => theme.mq.large} {
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`

const StyledShowcaseWrapper = styled.div`
  margin-top: 4.8rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: 5.6rem;
  }
`

export { StyledSectionWrapper, StyledShowcaseWrapper }
