import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import sortBy from "utils/sortBy"
import ChecklistItem from "./ChecklistItem/index"
import HeavyLiftingAnimation from "./HeavyLiftingAnimation"
import {
  HeavyLiftingWrapper,
  StyledChecklistWrapper,
  StyledChecklist,
  StyledTextContent,
  paragraphStyles,
  headingStyles,
} from "./HeavyLifting.styles"

const HeavyLifting = ({ sectionData }) => {
  const checklistItems = sortBy(sectionData.highlightsSection, ["position"])

  return (
    <>
      <HeavyLiftingWrapper>
        <StyledChecklistWrapper>
          <StyledChecklist>
            {checklistItems.map((item) => (
              <ChecklistItem key={item.position}>
                {item.highlight}
              </ChecklistItem>
            ))}
          </StyledChecklist>
        </StyledChecklistWrapper>
        <StyledTextContent>
          <H as="h3" extraStyles={headingStyles}>
            {sectionData.header}
          </H>
          <P extraStyles={paragraphStyles}>{sectionData.description}</P>
        </StyledTextContent>
      </HeavyLiftingWrapper>
      <HeavyLiftingAnimation
        labels={sectionData.animationData.processLabelSection.map(
          (item) => item.description
        )}
      />
    </>
  )
}

export default HeavyLifting

HeavyLifting.propTypes = {
  sectionData: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
    animationData: PropTypes.shape({
      processLabelSection: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        })
      ),
    }),
    highlightsSection: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.number,
        highlight: PropTypes.string,
      })
    ),
  }).isRequired,
}
