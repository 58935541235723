import styled, { css } from "styled-components"

const StyledSectionHeader = styled.div`
  text-align: left;

  ${({ theme }) => theme.mq.large} {
    text-align: center;

    max-width: 80%;
    margin: 0 auto;
  }
`

const learnMoreButtonStyles = css`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: 600;
  color: ${({ theme }) => theme.color.septenary03};
  margin-top: 2.4rem;

  ${({ theme }) => theme.mq.large} {
    margin-top: auto;
  }

  svg {
    margin-left: 1rem;
  }
`

export { StyledSectionHeader, learnMoreButtonStyles }
